/* MOBILE OVERRIDES FOR MCC */

@media (max-width: 600px) {
    /* MCC MAIN BODY & DIV WRAPPER CHANGES *///////////////////////////////////////////
    body {
        font-family: Arial, Helvetica, sans-serif !important;
    }
    header {
        nav {
            justify-content: center;
            padding: 10px;
            div {
                &.brand {
                    padding: 0;
                    margin-right: 0;
                    img {
                        &.logo {
                            height: auto;
                            width: 100%;
                            max-width: 222px;
                            max-height: 35px;
                        }
                    }
                }
            }
        }
    }
    
    .newClaim-wrapper {
        max-width: 100% !important;
    }
    main {
        .main-inner-wrapper:not(.no-mobile) {
            .emphasis-bold {
                color: var(--btnBorderColor) !important;
            }
        }

        newclaim {
            .main-inner-wrapper:not(.no-mobile) {
                > .header {
                    width: 100vw !important;
                    margin-top: -5px;
                    padding-top: 20px;
                }
            }
        }
    }

    .main-inner-wrapper:not(.no-mobile), .content-wrapper, .review-container-wrapper:not(.no-mobile) {
        padding: 0px;

        .button-wrapper {
            width: 95% !important;
        }

        > .button-wrapper {
            position: fixed;
            bottom: 20px;
            width: 100% !important;
            background: white;
            box-shadow: 0px -8px 15px -3px rgba(0,0,0,0.1);
            padding: 0px;
            align-items: center;
            justify-content: space-around;
            z-index: 4;

            &:only-child {
                margin: 0 auto;
            }

            button.btn {
                font-size: 20px;
                font-weight: 400 !important;
                padding: 8px 10px !important;
            }
        }

        .claims-wrapper {
            max-width: 100%;

            .button-wrapper {
                div.icon {
                    width: 100% !important;
                    height: auto;
                    font-size: 20px;
                    padding: 15px;
                    font-weight: 400 !important;
                    border-radius: 8px;
                    min-height: 68px;

                    &.selectedService {
                    }

                    img {
                        display: none;
                    }
                }
            }
        }

        .claims-innertext {
            strong {
                font-weight: 400;
                color: black !important;
            }

            color: black;
        }
        .consent-text.white-wrapper {
            background: white;
            padding: 15px;
        }
        .side-by-side {
            display: flex;
            .button-wrapper {
                width: 49% !important;
                justify-content: center;
                align-items: center;
            }
        }
        .reviewSubmissionPopUp {
            .button-wrapper {
                padding-left: 10px;
            }
        }
        .selectedService {
            .claims-innertext {
                p {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    margin: 0 auto;
                    margin-top: 0px !important;
                    color: black !important;
                    max-width: 80%;
                }

                strong {
                    font-size: 16px;
                    font-weight: 500;
                    display: block;
                }
            }
        }

        .emergencyResponse {
            width: 100% !important;
            padding: 0px 7px;

            .selectedService {
                .claims-innertext {
                    strong {
                        font-size: 20px;
                    }
                }
            }
        }

        .emergencyResponseReportNum-wrapper {
            width: 100% !important;
            padding: 0px 15px;
        }

        .reportNumber-wrapper {
            align-items: flex-start;

            label {
                color: black;
                font-size: 16px;
            }

            input {
                width: 100% !important;
                border-radius: 0px !important;
                font-weight: 400 !important;
            }
        }

        textarea {
            border-radius: 0px !important;
            border: 1px solid black !important;
            color: black !important;
        }

        .contactNameDisplay, .contactAddressDisplay {
            font-weight: 500;
            font-size: 16px;
            color: black;
        }

        .contactNameDisplay {
            margin: inherit !important;
        }
        /* MCC FONT / TEXT / PARAGRAPH STYLE CHANGES */
        span.text-info {
            color: black !important;
            background: #d1ecf1;
            width: 100%;
            font-size: 12px;
            text-align: center;
            padding: 5px;
            margin-top: -15px;
        }
    }
    main .main-inner-wrapper .input-wrapper .row div label{
        font-weight: 400;
        color: #000000;
    }
    claimtype, uploaddocument, uploadreceipt {
        .alert.show {
            margin-top: -15px;

            h4.alert-heading {
                font-size: 14px;
                text-align: center;
                font-weight: 600;
                color: black;
            }

            hr {
                display: none;
            }

            span.h5 {
                text-align: center;
                display: block;
            }
        }

        .input-wrapper {
            > .button-wrapper {
                > .subCategory {
                    > span {
                        > em {
                            font-size: inherit !important;
                            color: var(--btnBorderColor) !important;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .area-wrapper-ui {
            flex-direction: column;
            text-align: center;
            font-size: 14px;
            background: red;
            padding: 15px 20px;
        }

        p.header {
            span {
                font-weight: 400 !important;
            }

            span.individualsName {
                font-weight: 600 !important;
            }
        }

        .remove-padding-mobile {
            padding: 0px !important;
        }
        /*RADIO BUTTON TWEAK*/
        .d-flex {
            label.d-flex {
                margin-right: 20px !important;
                margin-left: 0 !important;
                width: auto;
            }
        }

        .highlight-wrapper, .highlight-ui {
            padding: 15px 30px !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            color: black !important;
            background: #d1ecf1;
            margin-top: -15px;
            line-height: 1.3;
            text-align: left !important;

            label {
                color: black !important;
                font-weight: bold !important;
            }

            span {
                strong a {
                    color: blue !important;
                    font-weight: 600;
                    text-decoration: underline;
                    text-transform: lowercase;
                    font-family: Arial;
                    overflow-wrap: anywhere;
                }
            }

            .highlight-text-bold-ui {
                margin-bottom: 15px;
            }

            .highlight-text-ui {
                font-weight: 400;
                text-align: left !important;

                &.text-center {
                    text-align: left !important;
                }
            }
        }

        .help-block-ui {
            margin-top: 20px !important;

            > div {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 14px;
                    margin-top: -10px;
                    margin-left: 3px;
                }

                a {
                    color: #0713A0;
                    font-size: 16px;
                    text-decoration: underline !important;
                }
            }
        }

        .alert {
            border-radius: 0px;
        }

        .alert-info {
            span.h5 {
                font-size: 13px;
                color: black;
            }
        }
    }
    .fieldLabel-wrapper.highlight-ui {
        flex-direction: column;
        width: 100vw;
        margin-left: -15px;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 15px !important;
        label {
            margin-bottom: 10px;
        }
        .text-center {
            text-align: left!important;
        }
    }
    .jumbotron {
        background-color: transparent !important;
        h1.display-2 {
            font-size: 2rem;
        }
    }
    .help-block-ui {
        margin-top: 20px !important;

        > div {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;

            i {
                font-size: 14px;
                margin-top: -10px;
                margin-left: 3px;
            }

            a {
                color: #0713A0;
                font-size: 16px;
                text-decoration: underline !important;
            }
        }
    }
    newClaim {
        .main-inner-wrapper:not(.no-mobile) {
            padding: 5px 0px 30px 0px;
        }

        .emergencyHeader {
            width: 100% !important;
            display: inline-block;
            padding: 10px 15px;
            border-radius: 0px;
            margin-top: -15px;

            a {
                font-weight: bold;
                color: #2041CB;
            }

            h4, .h5 {
                font-size: 17px;
                display: block;
            }
        }

        div.icon {
            &.cursor {
                width: 100%;
                height: 68px;
            }

            &.selectedService {
                width: 100%;
                min-height: 68px;
            }
        }

        .button-wrapper {
            div.claims-outertext-wrapper {
                div.claims-innertext {
                    width: 100%;
                    font-size: 20px;
                    padding: 0px 40px;
                    line-height: 1.2;

                    strong {
                        font-weight: 400;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }

            .selectedService {
                div.claims-outertext-wrapper {
                    div.claims-innertext {
                        strong {
                            font-size: 20px !important;
                        }
                    }
                }
            }
        }
    }
    /* MCC LAYOUT | FOOTER | BUTTON CHANGES */
    layout {
        background: #f4f4f4;
        > div {
            padding-bottom: 150px;
        }
        footer, .content-wrapper .button-wrapper {
            position: fixed;
            bottom: 0;
            font-size: 12px;
            padding: 5px 0;
            z-index: 5;
        }
        .content-wrapper .button-wrapper {
            bottom: 20px;
        }
    }
    .main-inner-wrapper:not(.no-mobile), .content-wrapper, .review-container-wrapper:not(.no-mobile) {
        .button-wrapper {

            .icon.cursor {
                position: relative;

                img {
                    position: absolute;
                    left: 15px;
                    max-width: 52px;
                    height: auto;
                }
                img[src*="/content/images/blank-mobile.png"] {
                    display: none;
                }
            }
        }
    }
    #motorhome {
        .claims-inner-text {
            width: 83%;
            display: inline-flex;
            text-align: center;
        }
    }
    .content-wrapper label, .content-wrapper myClaimsPic .invalid-text, myClaimsPic .content-wrapper .invalid-text, .content-wrapper smsConsent .invalid-text, smsConsent .content-wrapper .invalid-text {
        font-size: 16px;
        font-weight: 300;
    }
    .content-wrapper .car-detail-wrapper {
        p {
            font-size: 18px;
        }
        margin-top: -30px;
    }
    main {
        &#main {
            margin-top: 0px;
            background: #f4f4f4;
            overflow-x: hidden;
        }

        p {
            color: black;
        }

        vehicledetail .carInfo p, vehicleTheftDetails .carInfo p, nonPolicyVehicleTheft .carInfo p, nonPolicyVehicle .carInfo p {
            font-size: 18px !important;
            background: white;
            margin-top: -40px;
            padding-bottom: 25px;
        }

        vehicledetail .carInfo, vehicleTheftDetails .carInfo, nonPolicyVehicleTheft .carInfo, nonPolicyVehicle .carInfo {
            padding-bottom: 0px;
        }
        /* MCC FORM | BREADCRUMB | MORE BUTTON CHANGES FOR MOBILE VIEW */
        .main-inner-wrapper:not(.no-mobile), .content-wrapper {
            position: static;

            .header {
                font-size: 18px;
                margin-top: 0px;
                padding: 15px 10%;
                padding-bottom: 23px;
                background: white;
                height: auto;
            }

            .invalidMessage {
                flex: 100%;
                border-radius: 0px;
            }

            .input-wrapper {
                padding: 0px 15px;
                width: 100% !important;

                .input-group {
                    input {
                        width: 100%;
                        max-width: 100%;
                        flex: 100;

                        &:first-of-type {
                            margin-right: 3px;
                        }
                    }
                }

                .row {
                    div {
                        input:not(.mat-mdc-input-element), select, mat-form-field {
                            height: 40px !important;
                            border-radius: 0px;
                            border: 1px solid #707070;
                            font-weight: 400;
                            font-family: Arial, Helvetica, sans-serif;
                            background-color: #fff;
                        }

                        .mat-mdc-input-element {
                            border: none !important;
                            font-weight: 400 !important;
                            font-family: Arial, Helvetica, sans-serif !important;
                            background-color: #fff;
                        }
                    }
                }
            }

            > .input-wrapper {
                .fieldLabel-wrapper {
                    label {
                        font-weight: 400 !important;
                        color: #000000 !important;

                        &.invalid {
                            color: #c1272d !important;
                        }
                    }
                }

                > .row {
                    margin: 0px;
                    flex-direction: column;
                }
            }

            .d-flex.justify-content-center.input-wrapper {
                padding: 0;
                margin: 0;
            }

            .button-wrapper.col-lg-12 {
                padding: 0;
            }
        }

        .main-inner-wrapper:not(.no-mobile):not(:first-of-type) {
            margin-top: -16px;
        }

        .content-wrapper {
            margin: 0;
            width: 100%;
        }
    }
    vehicleInfo, vehicledetail {
        input, select{
            height: 40px !important;
            border-radius: 0px !important;
            border: 1px solid #707070 !important;
            font-weight: 400 !important;
            font-family: Arial, Helvetica, sans-serif !important;
            padding: 0px 8px !important;
        }

        .column-1 {
            padding: 0!important;
        }
    }
    div.subCategory {
        width: 100% !important;
        min-height: 68px !important;
    }

    breadcrumb {
        background:white;
        .mobile {
            margin-bottom: 0px;
            margin-top: 10px;
            .outer-wrapper {
                .circle {
                    width: 30px;
                    height: 30px;
                }
            }
        }

    }
    .main-inner-wrapper:not(.no-mobile), .content-wrapper, .review-container-wrapper:not(.no-mobile) {
        .button-wrapper {
            .icon.cursor {
                min-height: 68px;
                width: 100%;
                height: auto;
                padding: initial;
            }
        }
    }
    .emergencyResponse .icon {
        margin: 0 !important;
        margin-bottom: 10px !important;
        font-size: 20px;
    }
    .main-inner-wrapper:not(.no-mobile) .input-wrapper, .content-wrapper .input-wrapper, .review-container-wrapper:not(.no-mobile) .input-wrapper {
        span {
            margin: 0 auto;
        }

        span.checkmark {
            margin: 0;
        }

        .subCategory:hover {
            cursor: pointer;
        }

        .subCategory span {
            margin: 0 !important;
            text-align: center;
            line-height: 20px;
            text-align: center !important;
        }

        .subCategory span em {
            font-size: 15px;
            margin-bottom: 0px !important;
            font-style: normal;
        }

        .subCategory, div.subCategory {
            width: 99%;
            font-size: 20px;
            font-weight: 300;
            color: black !important;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 2px 2px 15px RGB(0 0 0 / 20%);

            i.fas {
                display: none;
            }
        }

        .subCategory.selected, div.subCategory.selected {
            background: white !important;
            color: black !important;
            border: 1px solid var(--btnBorderColor) !important
        }

        .subCategory.selected:hover, div.subCategory.selected:hover {
            background: white !important;
            color: black !important;
            border: 1px solid var(--btnBorderColor) !important
        }

        .subCategory:hover, div.subCategory:hover {
            background: white !important;
            color: black !important;
            border: 1px solid var(--btnBorderColor) !important;
        }

        button {
            &.person, &.vehicle, &.phone {
                width: 99%;
                font-size: 20px;
                font-weight: 300;
                text-align: center;
                min-height: 68px;

                &.selected {
                    background: #ffffff !important;
                    color: black !important;
                    border: 1px solid var(--btnBorderColor) !important;
                }

                &:hover {
                    background: #ffffff !important;
                    color: black !important;
                    border: 1px solid var(--btnBorderColor) !important;
                }
            }

            &.phone {
                min-height: 45px;
                height: 45px;
                box-shadow: none;
            }

            i {
                display: none;

                &.fa-plus-circle {
                    display: inline-block;
                    font-size: 1.3em;
                    color: #000000 !important;
                    padding-right: 5px;

                    &:before {
                        content: "+";
                    }
                }
            }

            &.addNew {
                display: flex !important;
                align-items: center;

                span {
                    display: contents;
                }
            }

            span {
                margin: 0 auto;

                &:nth-of-type(2) {
                    display: inline-block;
                    padding-left: 7px;
                    font-size: 18px;
                }
            }

            &.subCategory span {
                margin: 0 !important;
                text-align: center;
            }

            &.subCategory span em {
            }

            &.subCategory, &div.subCategory {
                width: 99%;
                font-size: 20px;
                font-weight: 300;
                color: black !important;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.subCategory.selected, &div.subCategory.selected {
                background: white !important;
                color: black !important;
                border: 1px solid var(--btnBorderColor) !important
            }

            &.subCategory.selected:hover, &div.subCategory.selected:hover {
                background: white !important;
                color: black !important;
                border: 1px solid var(--btnBorderColor) !important
            }

            &.subCategory:hover, &div.subCategory:hover {
                background: white !important;
                color: black !important;
                border: 1px solid var(--btnBorderColor) !important;
            }
        }
    }
    .input-wrapper button.person.selected:hover, .input-wrapper button.vehicle.selected:hover, .input-wrapper button.subCategory.selected:hover, .input-wrapper div.subCategory.selected:hover, .input-wrapper button.phone.selected:hover {
        color: inherit !important;
    }
    /* Fix weird bug for Angular class callout */
    button.ng-star-inserted:not(th, .mat-form-field-suffix) {
        width: 100%;
    }
    .mat-form-field-suffix {
        width: 24px;
        height: 24px;
    }
    /* HIDE ICONS for emergency response selection */
    emergencyResponse {
        .button-wrapper {
            .icon {
                img {
                    display: none;
                }
            }
        }
    }
    policyvehicles {
        button.vehicle {
            .policyMember {
                order: 3;
                display: flex;
                max-width: 70px;
                text-align: center;
                align-items: center;
                justify-content: center;
                color: white;
                text-transform: uppercase;
                background: var(--btnBorderColor) !important;
                border: none !important;
                font-size: 12px;
                padding: 3px 10px;
            }
        }
    }
    selectothercontact {
        width: 100%;
    }
    insuredvehicleselect {
        .input-wrapper {
            button.vehicle.selected {
                background: white !important;
                color: black !important;
            }

            button.vehicle {
                vehicleicon {
                    position: absolute;
                }
            }
        }
    }
    claimType .input-wrapper button.subCategory, claimType div.subCategory {
        min-height: 68px;
    }
    div {
        >.input-wrapper {
            margin-bottom: 5px;
        }
    }
    newclaim {
        p.header {
            width: fit-content !important;
        }
    }
    div.icon {
        &.selectedService {
            border: 2px solid var(--btnBorderColor) !important;
            background: white !important;
        }
    }
    div.icon.cursor {
        &.selectService {
            padding: 10px !important;
        }
    }
    .input-wrapper {
        button.person {
            span {
                text-align: center;
                word-break: break-word;
            }
        }
    }
    .input-wrapper {
        button.button-reversed {
            &:hover,&:active {

            }
        }
    }
/* TEXT and DATE PICKER CHANGES */
    .textConfirmation {
        font-size: 18px !important;
        font-weight: 400;
        background: white;
        width: 100%;
        margin-top: 0px;
        padding: 15px;
        color: black;
        b {
            font-weight: bold;
            display: block;
        }
    }
    smsconsent {
        > div {
            p.consent-text {
                padding: 0px 15px;
            }
            p.consent-text:first-of-type{
                background: white;
                margin-top: -40px;
                padding-bottom: 15px;

            }
        }
    }
    insuredvehicleselect {
        button.vehicle {
            img {
                max-width: 59px !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }
    .alert-danger {
        width: 90%;
    }
    jumbotron {
        background-color: transparent;
        .display-2 {
            font-size: 3.5rem;
        }
    }
    fileupload {
        font-size: 14px !important;
    }
    .fileUploadArea {
        .text-center{
            span {
                font-size: 14px !important;
            }
            span:first-of-type {
                font-weight: bold;
                margin-bottom: -20px;
                display: block;
            }
        }
        .warning-ui {
            span {
                margin-bottom: 0 !important;
            }
        }
    }

    hasopenclaim {
        .main-inner-wrapper:not(.no-mobile) {
            padding: 0px;
        }

        .container {
            margin: 0;
            width: auto;
            padding: inherit;
        }
    }
    #incidentDatePicker {
        width: 100%;
    }
    select[name=AMPM] {
        text-transform: lowercase;
    }
    .main-info-text-ui {
        span {
            font-size: 13px;
            text-align: center;
            padding: 0px 15px;
        }
    }
    .contact-info-text-ui {
        color: black;
        margin-top: -20px;
        section {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                label {
                    margin: 0;
                    text-align: right;
                    padding-right: 2px;
                    width: auto !important;
                }
                label, span strong {
                    font-size: 13px;
                    color: black;
                    a {
                        font-weight: bold;
                        color: black;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .opt-header-ui {
        font-weight: 500 !important;
        margin-top: -35px;
        background: white;
        padding-bottom: 25px !important;
    }
    .option-selector-wrapper-ui {
        margin-left: 0px !important;
        margin-right: 0px !important;
        &.justify-content-around {
            justify-content: flex-start !important;
        }

        label.container {
            margin-bottom: 0px;
            padding-left: 5px;
        }
    }
    smsconsent {
        .phone-input-wrapper {
            padding: 0px 15px;
            width: 100%;
            input {
                width: 100%;
                border-radius: 0px !important;
            }
            .d-flex.margin-top {
                flex-direction: column;
            }
        }
    }
/* REARRANGE HOW INFO DISPLAYS ON MOBILE */
    .main-inner-wrapper:not(.no-mobile) {
        .red {
            background: #f3dede;
            font-size: 14px;
            width: 100%;
            padding: 10px 15px;
            text-align: center;
            margin-top: -15px;
            margin-bottom: 15px;
            border-top: 1px solid #c93b3b;
            border-bottom: 1px solid #c93b3b;
        }
    }
    .information-breakdown-ui {
        display: flex;
        flex-wrap: wrap;
        background: #d1ecf1;
        font-size: 13px;
        width: 100%;
        padding: 8px 8px;
        justify-content: center;
        margin-top: -15px;

        strong em {
            font-style: normal;
            padding-left: 3px;
        }

        > div {
            display: flex;
            align-items: center;

            label {
                width: auto;
                margin: 0;
            }
        }

        > div:nth-of-type(1), > div:nth-of-type(3) {
            width: auto;
        }

        > div:nth-of-type(1) {
            order: 1;
            padding-right: 5px;
            margin-right: 5px;
            border-right: 1px solid #c9cbcb;
            display: flex;
            align-items: center;
            justify-content: end;
        }

        > div:nth-of-type(2) {
            order: 3;
        }

        > div:nth-of-type(3) {
            order: 2;
            align-items: center;
        }
    }
    .main-inner-wrapper:not(.no-mobile), .content-wrapper, .review-container-wrapper:not(.no-mobile) {
        .button-wrapper button#continueBtn, button#backBtn {
            width: 45% !important;
            border-radius: 4px !important;
            border: 2px solid var(--btnBorderColor) !important;
        }
    }
    myClaimsPic button.continue-btn {
        width: 45% !important;
        border-radius: 4px !important;
        border: 2px solid var(--btnBorderColor) !important;
    }
    lossdescription {
        .main-inner-wrapper:not(.no-mobile) {
            .header {
                margin-bottom: 25px;
            }

            > div textarea {
                border-radius: 0px;
                min-height: 355px !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                padding: 15px 10px;
            }
        }
    }
    claimreporter {
        .main-inner-wrapper:not(.no-mobile) {
            padding: 0px 15px;
        }
    }
    div {
        textarea {
            border-radius: 0px;
            font-size: 16px !important;
            font-weight: 400 !important;

            &::placeholder {
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 18px !important;
            }
        }
    }
    .row {
        > div:not([class]) {
            width: -webkit-fill-available;
        }
    }
    .main-inner-wrapper:not(.no-mobile) {
        .row.w-150 {
            margin: auto;
        }

        > div:not([class]) {
            width: -webkit-fill-available;
            z-index: 1;
        }
    }
    vehicleinfo {
        .carInfo {
            background: white;
            margin-top: -16px;
            padding-bottom: 0px;
            margin-bottom: 15px;
            p {
                font-size: 20px !important;
            }
        }
    }
    .alert-dismissible {
        .close {
            width: auto;
        }
    }
    lossdescription .main-inner-wrapper:not(.no-mobile) > div:not([class]) {
        width: 100%;
        padding: 0px 15px;
    }
/* INCIDENT REPORT STYLES */

    .review-container-wrapper {
        padding: 0px !important;
        max-width: auto !important;
    }

    review {
        header {
            height: auto;
            padding: 5px;
            margin-bottom: 0px;
            font-size: 14px;

            .ExpandAll {
                display: block;
                width: 100px;
                font-size: 13px;
                color: #2B589D;
                text-decoration: underline;
            }

            .media {
                h5 {
                    font-size: 14px;
                }
            }
        }

        header {
            button.editBtn, button.editBtn.selected {
                background-color: #8e8e8e;
            }
        }

        cardFields-wrapper {
            > span {
                font-size: 13px;
                padding-left: 0px;
                font-weight: 600;
                display: block;
                line-height: 1.3;

                &:first-of-type {
                    margin-top: 10px;
                }
            }
        }

        textarea {
            font-size: 14px !important;
            font-family: Arial, Helvetica, sans-serif !important;
        }

        textarea[ng-reflect-is-disabled="true"] {
            border: none !important;
            padding: 0px !important;
            font-size: 14px !important;
            font-family: Arial, Helvetica, sans-serif !important;
        }

        .review-input-wrapper {
            padding: 5px;

            header {
                .policyMember {
                    color: white;
                    border: none;
                    background-color: var(--btnBorderColor);
                    font-size: 10px !important;
                    margin-right: 1px;
                    min-width: 60px;
                    text-align: center;
                }
            }

            &.selected {
                box-shadow: none;
            }

            input:disabled {
                padding-left: 0px !important;
                color: black;
            }
        }

        .cardFields-wrapper {
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 5px;

            &.selected {
                margin-top: -10px !important;
            }

            .labelInputWrapper {
                padding-left: 0px;
            }
        }
    }
    vehicleInfo, vehicledetail {
        .content-wrapper {
            .row {
                max-width: 100% !important;
                .section-group-1, .section-group-2 {
                    padding: 0 !important;
                }
            }
        }
    }
    callinclaim {
        .main-inner-wrapper:not(.no-mobile) {
            > p.h5 {
                background: white;
                max-width: initial;
                padding: 15px;
                width: 100%;
                display: block;
                font-size: 20px;
                font-weight: 500 !important;
            }
        }
    }
    confirmation {
        div {
            top: auto;
        }

        .textConfirmation {
            font-size: 16px !important;
            font-weight: 600 !important;
        }

        .main-inner-wrapper:not(.no-mobile) {
            p.emailConfirmationNotice {
                background: white;
                margin: 0;
                padding-top: 35px !important;
                display: block;
                font-size: 14px;
            }

            > .input-wrapper {
                background: white;
                margin-top: -25px;
                z-index: 0;
            }
        }

        .row.justify-content-center {
            font-size: 13px;
            background: #d1ecf1;
            width: 100vw;
            margin-left: -15px !important;

            &:first-of-type {
                margin-top: -35px !important
            }

            .table {
                margin: 10px 0;

                &.convert-flexbox {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    align-items: center;
                    justify-content: center;

                    tr.ng-star-inserted {
                        width: 100%;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                td {
                    padding: 4px;
                    border: none !important;
                }

                > tr:last-of-type {
                    border-bottom: 15px solid #d1ecf1;
                }

                > tr:first-of-type {

                    td:first-of-type {
                        text-align: center;
                        height: 40px;
                        vertical-align: middle;
                    }

                    td:nth-child(2) {
                        text-align: left;
                        height: 40px;
                        vertical-align: middle;
                    }

                    &.claimNumber {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        td:first-of-type {
                            text-align: left;
                            height: auto;
                            display: contents;
                            width: auto;
                        }

                        td:nth-child(2) {
                            text-align: left;
                            height: auto;
                            width: auto;
                            padding-left: 4px;
                        }
                    }
                }

                tr {
                    &.claimNumber, &.adjusterName, &.adjusterPhone {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        td:first-of-type {
                            text-align: left;
                            height: auto;
                            display: contents;
                            width: auto;
                        }

                        td:nth-child(2) {
                            text-align: left;
                            height: auto;
                            width: auto;
                            padding-left: 4px;
                        }
                    }
                }
            }
        }
    }
    .half-wrap {
        width: 35% !important;
    }
    .relDocInfo-wrapper {
        background: #f4f4f4 !important;
        padding: 15px !important;
        margin-top: -15px !important;
        color: black;
        a {
            font-weight: bold !important;
        }
    }
    .reviewPageHeading {
        font-size: 18px;
        background: white;
        padding: 0px 40px;
    }
    .reviewSectionHeader {
        background: #d1ecf1;
        width: 100vw;
        margin-left: -15px;
        margin-top: 5px;
        margin-bottom: 5px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;

        h5 {
            color: black;
            font-weight: bold;
            text-align: left;
            width: 100%;
            font-size: 14px;
        }

        &.h5-header-ui {
            background: white !important;
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            h5 {
                font-size: initial;
                text-align: center;
                padding-bottom: 10px;
            }
        }
    }
    .add-padding-ui {
        padding: 0px 15px;
    }
    .reverse-checkbox {
        label {
            display: flex !important;
            &:before {
                margin-left: 0px !important;
                margin-right: 8px !important;
            }

            &:after {
                right: auto !important;
                left: 2px !important;
            }
        }
    }
    lossAddress input[type=checkbox] + label::before, lossAddress smsConsent input[type=checkbox] + .invalid-text::before, smsConsent lossAddress input[type=checkbox] + .invalid-text::before, lossAddress myClaimsPic input[type=checkbox] + .invalid-text::before, myClaimsPic lossAddress input[type=checkbox] + .invalid-text::before {
        width: 20px;
        max-width: 20px;
        min-width: 20px;
        height: 20px;
    }
    main .main-inner-wrapper:not(.no-mobile) .input-wrapper .row div {
        &.reverse-checkbox {
            label {
                font-weight: bold !important;
                font-size: 14.5px;
            }
        }
    }
    .fieldLabel-wrapper {
        align-items: flex-start;
    }
    button {
        &.addNew {
            &.button-reversed {
                border: 0px solid #000000 !important;
            }
        }
    }
    /*3rd Party Mobile specific*/
    main {
        &#main {
            claimreporter {
                .main-inner-wrapper:not(.no-mobile) {
                    p.header {
                        width: 100vw !important;
                    }
                }
            }

            .main-inner-wrapper:not(.no-mobile) {
                > p.header:first-of-type {
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }

                > p.header:not(:first-of-type) {
                    margin: 0;
                    padding-bottom: 0px;
                }

                > p.header:last-of-type {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        selectinsuredothercontact {
            .main-inner-wrapper:not(.no-mobile) {
                > p.header:last-of-type {
                    padding-bottom: 15px;
                    margin-top: -50px;
                }
            }
        }

        .main-inner-wrapper:not(.no-mobile) {
            > p.header:not(:first-of-type) {
                padding-bottom: 15px;
                margin-top: -30px;
            }
        }

        nonpolicyvehicle {
            select.state {
                margin-bottom: 0px;
            }
        }

        selectclaimantothercontact {
            .main-inner-wrapper:not(.no-mobile) {
                > p.header:first-of-type {
                    padding-bottom: 15px;
                }
            }
        }

        .main-inner-wrapper:not(.no-mobile) {
            .header-banner {
                > p.header:first-of-type {
                    margin-top: -50px;
                }
            }
        }

        .third-party-review {
        }

        review {
            .third-party-review {
                .review-input-wrapper {
                    .labelInputWrapper {
                        label {
                            font-family: Arial, Helvetica, sans-serif !important;
                        }
                    }
                }
            }
        }
    }
    /* Modal Popover for Mobile*/
    .addressValidationPopUp {
        color: #000000;
        .secondaryAddy {
            display: none;
        }
        > span {
            display: block;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }

        .button-wrapper {
            width: 100% !important;
            #origBtn, #newBtn {
                min-width: 150px;
                padding: 5px 10px;
                width: auto;
                margin: 10px;
                background-color: var(--btnFillColor) !important;
            }
            button {
                font-weight: 400;
                border: none !important;
                border-radius: 6px;
                box-shadow: none;
            }
        }
    }
    p.header span.bold {
        font-weight: bold !important;
    }
    .container-wrapper {
        > label.container {
            color: black !important;
            width: auto !important;
            padding: 0px 8px !important;
        }
    }
    insuredvehicleselect {
        .input-wrapper {
            button {
                &.vehicle {
                    vehicleicon {
                        position: relative;
                    }

                    span {
                        display: inline-block;
                        padding: 0px 15px;
                        text-align: center;
                    }
                }
            }
        }
    }
    /* FIX for Home Section*/
    .main-inner-wrapper.no-mobile {
        .input-wrapper button.person, .input-wrapper button.vehicle {
            margin: 0 auto;
            span {
                margin: 0 auto;
            }
        }
    }
}
/*GalaxyFold outerscreen 280px width specific css*/
@media (max-width: 374px) {
    .main-inner-wrapper:not(.no-mobile) {
        .button-wrapper {
            .icon {
                &.cursor {
                    img {
                        max-width: 42px;
                    }
                }
            }
        }
    }
    
}
@media (max-width: 359px) {
    .main-inner-wrapper:not(.no-mobile) {
        .button-wrapper {
            .icon {
                &.cursor {
                    img {
                        max-width: 30px;
                    }
                }
            }
        }
    }
    confirmation {
        .textConfirmation {
            top: -55px;
        }
    }
    .input-wrapper {
        button.person {
            span {
                font-size: 16px;
            }
        }
    }
    newClaim {
        .button-wrapper {
            div.claims-outertext-wrapper {
                div.claims-innertext-wrapper {
                    strong {
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }
        }
    }

    #vehicleDamageImage {
        zoom: 0.9 !important;
    }
    .content-wrapper .car-detail-wrapper {
        margin-bottom: -40px;
    }
    
}

