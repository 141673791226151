﻿@media screen and (max-width: 767px) {
    #claimTypeFlex {
        display: flex;
        flex-direction: row;
    }
 
/*    #mechanicalSection {
        order: 2;
    }
    #weatherEventSection {
        order: 3;
    }
    #theftSection {
        order: 4;
    }
    #fireSection {
        order: 5;
    }
    #vandalismSection {
        order: 6;
    }
    #singlecarSection {
        order: 1;
    }
    #multipleVehicleAccidentSection {
        order: 0;
    }
    #pedestrianSection {
        order: 7;
    }
    #glassOnlySection {
        order: 8;
    }*/
}


@media screen and (max-width: 767px) {
    #claimTypeThirdPartySingleFlex {
        display: flex;
        flex-direction: row;
    }
    #tireBlowoutSection {
        order: 5;
    }
    #roadHazardSection {
        order: 3;
    }
    #hitAndRunSection {
        order: 1;
    }
    #fallingObjectSection{
        order: 4;
    }
    #animalCollisionSection {
        order: 0;
    }
    #fixedPropertySection {
        order: 2;
    }
    #singleVehicleOtherSection {
        order: 6;
    }
}
@media screen and (max-width: 767px) {
    #claimTypeThirdPartyMultiFlex {
        display: flex;
        flex-direction: row;
    }
    #rearEndMultiSection {
        order: 2;
    }
    #hitAndRunMultiSection {
        order: 1;
    }
    #parkingLotMultiSection {
        order: 3;
    }
    #backingMultiSection {
        order: 4;
    }
    #intersectionMultiSection {
        order: 0;
    }
    #headOnMultiSection {
        order: 5;
    }
    #passingMergingMultiSection {
        order: 7;
    }
    #sideswipeMultiSection {
        order: 6;
    }
    #otherMultiSection {
        order: 8;
    }
}
@media screen and (max-width: 767px) {
    #newClaimFlex {
        display: flex;
        flex-direction: row;
    }
    #personalAutoSection {
        order: 0;
    }
    #homeSection {
        order: 1;
    }
    #watercraftSection {
        order: 5;
    }
    #motorcraftSection {
        order: 2;
    }
    #motorcycleSection {
        order: 4;
    }
    #commercialAutoSection {
        order: 3;
    }
    #farmSection {
        order: 7;
    }
    #lifeSection{
        order: 6;
    }
}

@media screen and (max-width: 767px) {
    #newClaimAdverseCarrierFlex {
        display: flex;
        flex-direction: row;
    }

    #personalAutoSection {
        order: 0;
    }

    #homeSection {
        order: 1;
    }

    #motorcraftSection {
        order: 2;
    }

    #motorcycleSection {
        order: 3;
    }

    #commercialAutoSection {
        order: 4;
    }

    #otherSection {
        order: 5;
    }
}

@media screen {
    #claimTypeAutoFlex {
        display: flex;
        flex-direction: row;
    }
    #tireBlowoutAutoFlex {
        order: 5;
    }
    #roadHazardAutoFlex {
        order: 3;
    }
    #hitAndRunAutoFlex {
        order: 1;
    }
    #fallingObjectAutoFlex {
        order: 4;
    }
    #animalCollisionAutoFlex{
        order: 0;
    }
    #fixedPropertyAutoFlex{
        order: 2;
    }
    #singleVehicleOtherAutoFlex{
        order: 6;
    }
}
@media screen{
    #claimTypeAutoMultiFlex {
        display: flex;
        flex-direction: row;
    }

    #rearEndedAutoMultiSection {
        order: 2;
    }
    #parkingLotAutoMultiSection {
        order: 3;
    }
    #backingAutoMultiSection {
        order: 4;
    }
    #intersectionAutoMultiSection {
        order: 0;
    }
    #headOnAutoMultiSection {
        order: 5;
    }
    #passingMergingAutoMultiSection {
        order: 7;
    }
    #sideswipeAutoMultiSection {
        order: 6;
    }
    #mvHitAndRunAutoMultiSection {
        order: 1;
    }
    #otherAutoMultiSection {
        order: 8;
    }
 }

@media screen {
    #claimTypeWeatherFlex {
        display: flex;
        flex-direction: row;
    }

    #earthquakeSection {
        order: 6;
    }

    #hailSection {
        order: 1;
    }

    #floodSection {
        order: 3;
    }

    #windTornadoSection {
        order: 2;
    }

    #snowSection {
        order: 4;
    }

    #lightningSection {
        order: 5;
    }
}