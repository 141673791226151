@import './defaults.scss';

header {
    // visibility:hidden;
    nav {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--navBarBottomColor);
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: var(--navBarBackgroundColor);
        color: $colorB3;

        div.brand {
            display: inline-block;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-right: 1rem;
            line-height: inherit;
            white-space: nowrap;

            img.logo {
                content: var(--logo);
                height: 65px;
            }
        }

        i {
            font-size: 1.75em;
            color: $colorB3;
            margin-left: auto;
        }
    }

    .userName {
        margin: 0 10px;
    }
}