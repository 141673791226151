@import './styles/defaults.scss';
@import './styles/Navbar.scss';
@import './styles/mixin.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700');
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");


// Has Open Claim
hasOpenClaim {
    .main-inner-wrapper {
        .container {
            & > p {
                max-width: inherit;
            }
        }
    }
}

callInEmergency {
    p.contactNumber {
        font-size: 1.50em;
    }
}

.fullWidth{
    width:100%;
}

.centerMargin{
    margin:auto;
}

.mat-mdc-input-element {
    border-radius: 6px !important;
    border: 1px solid #C6C6C6 !important;
    outline: none !important;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.10) !important;
    width: 100%;
    padding: 8px 10px !important;
    background-color: #fff !important;
    align-self: center;
    color: var(--appTextColor) !important;
    font-weight: 700 !important;
}

//New Claim
newclaim {
    width: 100%;

    .header {
        text-align: center;
        font-size: 1.85em;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
    }

    .emergencyHeader {
        display: inline;
        width: 950px !important;
        height: 100%;
        padding: 40px;
        margin-bottom: 25px;
        border-radius: 12px;
        background-color: #d1ecf1;
        box-shadow: 3px 3px 15px 0px rgba(0,0,0,0.15);
    }

    .content-outer-wrapper {
        display: flex;
        width: 90%;
        height: 100%;
        padding: 40px;
        margin-bottom: 25px;
        border-radius: 12px;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 25px 0px rgba(0,0,0,0.25);
    }

    .autoBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        background-color: #fff;
    }

    .content-inner-wrapper {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid var(--borderColor);
        position: relative;
        padding: 20px;

        img {
            width: 12.5em;
            width: 100%;
        }

        h4 {
            font-family: var(--fontFamilyDefault);
            font-size: 1.15em;
            font-weight: 300;
            text-align: center;
            width: 90%; /* IE specific fix*/
        }

        .contactNumber {
            margin-bottom: 0;
            padding-bottom: 20px;
            font-weight: 600;
            text-align: center;
            color: var(--emphasisColor);
            line-height: 1.25em;
            font-size: 3em;
            text-decoration: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    .button-wrapper {
        margin: 2px !important;

        .subCategory {
            width: 530px;
            height: 56px;
            // border: 1px solid $colorA5;
            border-radius: 4px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 30px;

            &:hover {
                background-image: linear-gradient($colorC2, $colorC3);
                color: $colorA1;
            }
        }

        div.claims-outertext-wrapper {
            width: 100%;

            div.claims-innertext {
                width: 100%;

                p {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-top: 15px;
                }
            }
        }
    }

    p.sectionHeader {
        text-align: center;
        width: 250px;
        font-size: 2em;
        padding: 0;
    }
}
// End New Claim

// Glass Only Page
glassonly {
    a[href^="tel:"] {
        font-size: 2em;
        text-align: center;
        color: $colorC5;
        line-height: 1.25em;
        text-decoration: none;
        padding: 0.5em;
        /*&::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f095";
            margin-right: 0.5em;
            color: $colorA1;
            font-size: 0.5em;
            border-radius: 50%;
            background-color: $colorA9;
            padding: 0.4em;
            vertical-align: 12%;
        }*/
    }

    .emergencyHeader {
        display: inline;
        height: 100%;
        padding: 40px;
        margin-bottom: 25px;
        background-color: #d1ecf1;
    }
}

// Date of Loss page
policysearch {
    width: 100%;

    .input-group {
        padding: initial !important;
        margin: initial !important;
    }

    .input-group-append {
        padding: initial !important;
        margin: initial !important;
        margin-left: 2px !important;
    }

    input {
        margin-top: 0px;
    }

    /*.input-group > input {
        border-top-right-radius: initial;
        border-bottom-right-radius: initial;
    }*/

    .custom-select {
        border-radius: 6px !important;
        margin-top: 3px !important;
        padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
    }
}

// Breadcumb styling
.mat-progress-bar-background, .mat-progress-bar-fill::after {
    background-color: var(--progressColor) !important;
}
.mat-progress-bar-buffer{
    background-color: $colorB8;
}

breadcrumb {
    display: flex;
    flex-direction: column;

    .mobile {
        display: none;
        margin: 20px auto;
        flex-wrap: nowrap;
        justify-content: center;

        .outer-wrapper {
            display: flex;
            align-items: center;
            margin: 10px 0;

            .inner-wrapper {
                display: flex;
                flex-direction: column;

                .line {
                    width: 70vw !important;
                    position: relative;
                }
            }

            .circle {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: var(--progressColorDefault);
                display: flex;
                justify-content: center;
                align-items: center;
                color: $colorA1;
                font-size: 16px;
                margin: 0 5px;

                &.completed, &.inprogress {
                    background-color: var(--progressColor);
                }
            }

            p {
                margin: 0 0 0 5px;
                font-size: 12px;
                font-weight: 400;
                color: $colorC5;
            }
        }
    }

    .desktop {
        display: flex;
        margin: 20px auto;
        width: 100%;
        justify-content: center;

        .outer-wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 15px 0;
            width: 121px;

            &:last-child {
                .inner-wrapper {
                    margin-top: 0;

                    .line {
                        .mat-progress-bar {
                            display: none !important;
                        }
                    }
                }
            }

            .inner-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .line {
                    width: 5.3em !important;
                    height: 2px !important;
                    margin: 0 -5px 0 -5px;

                    .mat-progress-bar {
                        height: 3px;
                    }
                }
            }

            .circle {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background-color: var(--progressColorDefault);
                display: flex;
                justify-content: center;
                align-items: center;
                color: $colorA1;
                font-size: 16px;
                margin: 0 5px;

                &.completed, &.inprogress {
                    background-color: var(--progressColor);
                }
            }

            p {
                margin: 5px 2px 0 0;
                height: auto;
                font-size: 12px;
                color: $colorC5;
                font-weight: bold;
            }
        }
    }
}

//End Breadcrumb

layout {
    display: flex;
    flex-direction: column;
    min-height: 100% !important;
    height: 100%;

    & > div {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }
    footer {
        text-align: center;
        justify-content: center;
        background-color: var(--footerColor);
        color: $colorA1;
        font-size: .8em;
        padding: 10px 0;
        width: 100%;
        margin: 0;
        clear: both;
    }

    footer:after {
        content: var(--footer);
    }
}

// Styling Claim Type Info
.openClaimTypeLabel {
    width: 150px;
    color: var(--labelFontColor);
}
strong {
    em {
        color: var(--appTextColor);
    }
}
// End Styling Claim Type Info

// remove required color in Firefox
:required:-moz-ui-invalid {
    box-shadow: none;
}
.invalid:-moz-ui-invalid {
    color: $colorC1 !important;
    border: 1.25px solid $colorC1 !important;
    font-weight: 700;
    box-shadow: 0 0 1.5px 1px red;
}
// todo
.invalid {
    color: $colorC1 !important;
    border: 1.25px solid $colorC1 !important;
    background-color: lighten(red, 45%) !important;
}

// removes background and border styling on invalid labels and i
label.invalid {
    color: $colorC1 !important;
    border: none !important;
    background-color: transparent !important;
}
i.invalid {
    color:$colorC1 !important;
    border: none !important;
    background-color: transparent !important;
}

.red {
    color: $colorC1 !important;
}
.cursor{
    cursor: pointer;
}
.invalidMessage {
    color: $colorC1;
    background-color: $colorA7;
    padding:7px !important;
    border-radius: 4px;
    font-size: 12px;

    i {
        margin: 0 10px 0 5px;
    }
}
html, body {
    font-family: var(--fontFamilyBody), var(--fontFamilyDefault) !important;
    color: $colorB2;
    width: 100% !important;
    height: 100%;
    background-color: $colorC6 !important;
}
html {
    background-position: center;
    // background-image: var(--background-img);
    background-size: auto;
    background-repeat: no-repeat;
    background-size: cover;
}
dashboard{
    .input-wrapper{
        width: auto !important;
        div.row{
            display:flex;
            justify-items:center;
            width:auto !important;
            form{

            }
        
        }
    }
}

main .main-inner-wrapper .input-wrapper .row div:not(.mat-mdc-form-field-underline, .mat-mdc-form-field-wrapper, .mat-mdc-form-field-flex, .mat-mdc-form-field-suffix, .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-wrapper, .mat-mdc-form-field-infix, .mat-mdc-text-field-wrapper){
    padding: 0 2px;
    margin:2px 0;
}

.no-margin {
    margin-top: 0em;
}

main {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 2em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;



    .main-inner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 1440px;
        margin: 0 auto;

        .header {
            color: var(--headerColor);
            text-align: center;
            font-size: 1.85em;
            width: 100%;
            padding-top: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }

        .policyMember {
            background-color: transparent;
            color: var(--policyMember);
            border: 1px solid var(--policyMember);
            border-radius: 4px;
            font-size: 8px;
            padding: 3px 7px;
            margin-left: 20px;
        }

        .emphasis-bold {
            color: var(--emphasisColor);
            font-weight: bold;
            font-size: 1em;
        }

        .input-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: inherit;
            width: 700px;

            .row {
                justify-content: center;

                div:not(.mat-mdc-form-field-underline, .mat-mdc-form-field, .mat-mdc-form-field-wrapper, .mat-mdc-form-field-flex, .mat-mdc-form-field-suffix, .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-wrapper, .mat-mdc-form-field-infix) {

                    select {
                        margin: 3px 0;
                        font-family: var(--fontFamilyDefault);
                        width: 100%;
                        height: 42px !important;
                        border-radius: 6px;
                        border: 1px solid #C6C6C6;
                        padding: 8px 10px 8px;
                        color: var(--appTextColor);
                        font-weight: 700;
                        box-shadow: 2px 2px 10px rgba(0,0,0,0.1);

                        option {
                            font-weight: 300 !important;
                        }

                        &.pickedValue {
                            color: $colorB3;
                            font-weight: 700;
                        }
                    }

                    .state {
                        option {
                            font-weight: 700 !important;
                        }
                    }

                    textarea {
                        color: var(--appTextColor);
                        font-weight: 500;
                        border-radius: 6px;
                        border: 1px solid #C6C6C6;
                        box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
                    }

                    label {
                        font-size: 1em;
                        font-weight: bold;
                        color: var(--labelFontColor);
                    }

                    input:not(.mat-mdc-input-element), .mat-mdc-input-element, mat-form-field, .mat-mdc-text-field-wrapper, .mat-mdc-form-field-focus-overlay {
                        height: 42px;
                    }
                }

                .container {
                    .container-wrapper {
                        display: flex;
                        align-items: center;
                        height: 42px;

                        .container {
                            display: flex;
                            margin: 0;
                            align-items: center;
                            font-size: inherit;
                            font-weight: 400;
                            color: $colorB2;

                            .checkmark {
                                margin-right: 10px;
                            }
                        }

                        .row {
                            width: 100%;
                        }
                    }
                }
            }

            label {
                font-weight: 400;
                font-size: 0.75em;
            }
        }

        p {
            max-width: 800px;
        }
    }

    p {
        font-size: 2em;
        color: inherit;
        text-align: center;
        color: $colorC5;
        font-weight: 300;
        line-height: 1.25em;
        padding-bottom: 30px;
        font-weight: bold;
    }

    .button-wrapper {
        align-self: center;
        margin: 5px 0 0;

        button.btn-primary {
            width: 150px;
            padding: 10px;
            margin: 20px 2px 20px;
            text-align: center;
            background-color: transparent;
            border: 1px solid !important;
            color: $colorB2;

            &:active, &:focus {
                outline: none !important;
                border: none !important;
                color: #fff;
                box-shadow: none;
            }
        }
    }
}

button.addNew {
    justify-content:center !important;
    border:1px dashed $colorC5 !important;
    &:hover {
        i {
            color: $colorA1 !important;
        }
    }
    &.selected{
        i{
            color:$colorA1 !important;
        }
    }
    i {
        color: $colorC5 !important;
        margin:0 !important;
    }
}

button.noDriver {
    border: 1px solid $colorC5 !important;
}

input:not(.mat-input-element, .mat-mdc-input-element){
    width: 100%;
    padding: 8px 10px;
    margin: 3px 0;
    border-radius: 6px;
    border: 1px solid #C6C6C6;
    align-self: center;
    color: var(--appTextColor);
    font-weight: 700;
    outline: none;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.10);
}



.mat-mdc-form-field-wrapper{
    bottom:18px;
}

.mat-mdc-form-field-bottom-align, .mat-form-field-focus-overlay, .mat-mdc-form-field-underline {
    display: none;
}

.mdc-line-ripple, .mdc-line-ripple::before, .mdc-line-ripple::after{
    display:none;
}

.mat-mdc-form-field-infix, .mdc-text-field{
    padding: 0px !important;
    min-height: 0px;
}

mat-form-field{
    margin: 3px 0;
    width:100%;
}

// Date of Incident datepicker
#incidentDatePicker{
    text-align:left;
    width: 185px;
    height: 50px;
 
}

#datePickerInput{
    width: 80%;
}

/*.mdc-icon-button  { display: none !important;}*/
/*.mat-mdc-form-field-icon-suffix {display: none !important;}
.mat-mdc-icon-button.mat-mdc-button-base {display: none !important;}
.mat-mdc-icon-button {display: none !important;}*/
.md-datepicker-button.md-icon-button {  display: none;}
.mat-mdc-form-field-flex { height: 10px !important;}

.mat-datepicker-custom .mat-datepicker-toggle {
    display: none;
}

button.person, button.subCategory, div.subCategory, button.vehicle, button.phone {
    min-height: 56px;
    height: auto; // this should account for word wrap on mobile.
    &:active, &:focus, &:visited {
        outline: none;
    }
}
button.person.selected, button.subCategory, div.subCategory, .selected, button.phone {
    &:active, &:focus, &:visited {
        outline: none;
    }
}
.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  label {
    font-size: .75em;
  }
  .car-detail-wrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
    vehicle-damages{
        padding-top: 50px;
    }
    p {
      font-size: 1em;
      margin: 10px;
      padding: 0;
    }
  }
}

.content-wrapper {
  .input-wrapper {
    width: 650px !important;
  }
}

.column-1 {
    display: flex;
    flex-wrap: wrap;

    .section-group-1 {
        .row {
            margin-bottom: 10px;

            select {
                display: block;
                padding: 9px 10px 8px !important;
                border-radius: 6px;
                border: 1px solid #C6C6C6;
                width: 100%;
                color: var(--appTextColor);
                font-weight: 700;
                outline: none;
                height: 42px !important;
                box-shadow: 2px 2px 15px rgba(0,0,0,0.1);

                option {
                    font-weight: 300 !important;
                    font-family: var(--fontFamilyDefault);
                }
            }

            .state {
                option {
                    font-weight: 700 !important;
                }
            }
        }
    }

    .container-wrapper {
        .container {
            display: flex;
            align-items: center;
            padding: 0;
            margin-right: 6px;

            span {
                margin-right: 8px;
            }
        }

        label.container:nth-child(2) {
            padding-left: 25px !important;
        }
    }

    .radio-buttons-wrapper {
        padding: 0;
    }
}
p.note {
    font-size: .75em;
    padding: 0 15px;
    text-align: left;
    height: 55px;
    width:100%;
    padding:0;
    margin:10px 0 0;
    strong{
        color:$colorB3;
    }
}
textarea {
    width: 100%;
    color: var(--appTextColor);
    font-weight: 700;
    outline: none;
    padding: 5px 10px;
}

.main-inner-wrapper {
  display: flex;
  align-items: center;
  p {
    font-size: 2em;
  }
  form{
      width:400px;
      input[value~="Submit"]{
        margin-top:4px;
      }
  }
}
.icon {
    background-color: rgba(255,255,255,.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 220px;
    height: 220px;
    padding: 30px;
    margin-bottom: 10px !important;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.15);

    p {
        font-size: 1.25em;
        margin-top: 30px;
    }

    svg {
        width: 100%;
        height: 100%;
    }
}
.claims-wrapper {
    .button-wrapper {
        margin: 2px !important;

        .subCategory {
            width: 530px;
            height: 56px;
            // border: 1px solid $colorA5;
            border-radius: 4px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 30px;

            &:hover {
                background-image: linear-gradient($colorC2, $colorC3);
                color: $colorA1;
            }
        }

        div.claims-outertext-wrapper {
            width: 100%;

            div.claims-innertext {
                width: 100%;
                strong {
                    color: $colorB3;
                }

                p {
                    font-size: 15px !important;
                    font-weight: 600;
                    margin-bottom: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}

contactinfo {

    .fieldLabel-wrapper {
        label {
            input[type='checkbox'] {
                height: inherit !important;
            }
        }
    }

    .input-wrapper {
        .row {
            .container-wrapper {
                .container {
                    align-items: center;
                    padding: 0;

                    input {
                        & + span {
                            margin-right: 10px;
                        }

                        label {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .mat-slider-horizontal {
        width: 100%;
        padding: 0 15px !important;

        div {
            margin: auto !important;
        }
    }
}

lossAddress {
    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox] + label {
        display: block;
        position: relative;
        cursor: pointer;
    }

    input[type=checkbox] + label::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #ccc;
        float: right;
        margin-left: 10px;
        border: 1px solid $colorB3;
    }

    input[type=checkbox]:checked + label::before {
        background-color: $colorB3;
    }

    input[type=checkbox]:checked + label::after {
        content: '';
        position: absolute;
        top: 5px;
        right: 2px;
        border-left: 4px solid white;
        border-bottom: 4px solid white;
        height: 8px;
        width: 16px;
        transform: rotate(-45deg)
    }

    #primaryAddressCheckbox {
        height: 20px;
        width: 20px;
    }
}


vehicledetail, vehicleTheftDetails, nonPolicyVehicleTheft, nonPolicyVehicle {

    select {
        margin: 3px 0;
        display: inline-block;
        padding: 8px 10px 8px !important;
        border-radius: 6px;
        border: 1px solid #ccc;
        width: 100%;
        color: var(--appTextColor);
        font-weight: 700;
        outline: none;
        height: 42px !important;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.1);

        option {
            font-weight: 300 !important;
            font-family: var(--fontFamilyDefault);
        }
    }

    .state {
        option {
            font-weight: 700 !important;
        }
    }

    label {
        font-size: 1em;
        font-weight: bold;
        color: var(--labelFontColor);
    }

    p {
        padding: 0;
    }

    .carInfo {
        padding-bottom: 40px;
        margin-top: 10px;

        p {
            font-size: 1.5em !important;
        }
    }

    label {
        color: var(--appTextColor);
    }

    textarea {
        color: var(--appTextColor);
        font-weight: 700;
        border-radius: 6px;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: 0 auto;

        .row {
            margin: 0 auto;

            div {
                margin: 2px 0;
                padding: 0 2px;
            }
        }

        .button-wrapper {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .container {
            span {
                margin-right: 8px;
            }
        }
    }

    input {
        height: 42px;
    }

    input[type=text] {
        position: relative !important;
        opacity: 1 !important;
    }

    i {
        color: $colorC1;
        margin: 0 5px 0;
        font-size: .5em;
    }

    label.container {
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: 6px;
    }

    .theftRadioButton {
        min-width: 25px;
    }

    .vehicleDiagram-front-label {
        position: relative;
        top: 19px;
        right: 75px;
        font-size: 14px !important;
    }

    .vehicleDiagram-back-label {
        position: relative;
        right: 78px;
        bottom: 184px;
        font-size: 14px !important;
    }
}

selectpolicycontact {
    .fieldLabel-wrapper {
        label {
            input[type='checkbox'] {
                height: inherit !important;
            }
        }
    }

    .input-wrapper {
        .row {
            .container-wrapper {
                .container {
                    align-items: center;
                    padding: 0;

                    input {
                        & + span {
                            margin-right: 10px;
                        }

                        label {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

vehicleinfo {
    .damageSeverity {
        padding: 0;
        margin: 0;

        .note {
            padding: 0;
        }

        .damageSeveritySlider {
            min-width: 100%;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin: 0 auto;

        .row {
            margin: 0 auto;

            .column-2 {
                display: flex;
                justify-content: center;
            }
        }

        .button-wrapper {
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .fieldLabel-wrapper {

            .unlistedVehicleParticipant {
                font-size: 0.75em;
                text-decoration: underline;
                cursor: pointer;
                position: absolute;
                right: 20px;
            }
        }

        .vehicleDiagram-front-label {
            position: relative;
            top: 19px;
            right: 79px;
        }

        .vehicleDiagram-back-label {
            position: relative;
            bottom: 187px;
            right: 79px;
        }
    }

    p {
        padding: 0;
    }

    .carInfo {
        padding-bottom: 40px;
        margin-top: 10px;

        p {
            font-size: 1.5em !important;
        }
    }

    label {
        color: var(--appTextColor);
    }

    .area-background[_ngcontent-ng-cli-universal-c1] {
        fill: #ffd740 !important;
    }

    input, .mat-mdc-form-field-wrapper{
        height: 42px;
    }
}

additionalPartyVehicle {
    .fieldLabel-wrapper {

        .unlistedVehicleParticipant {
            font-size: 0.75em;
            text-decoration: underline;
            cursor: pointer;
            position: absolute;
            right: 10px;
        }
    }
}

lossdescription {
    width: 100%;

    .main-inner-wrapper {
        & > div {
            width: 65%;

            textarea {
                color: var(--appTextColor);
                font-weight: 700;
                outline: none;
                padding: 5px 10px;
                border-radius: 6px;
                box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
            }
        }

        textarea {
            color: var(--appTextColor);
            font-weight: 700 !important;
        }
    }
}
/*------ Yes & No radio button Styling ------*/
.button-wrapper {
    div.yesBtn, div.noBtn {
        width: 250px;
        margin: 5px;
        border: 1px solid $colorB2;
        border-radius: 4px;

        .container {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0;

            & > div {
                width: 100%;
            }

            label {
                display: flex;
                margin: 0;
                padding: 0;
                height: 45px;

                p {
                    font-size: 1em;
                    font-weight: 400;
                    padding: 0;
                    margin: 0;
                    width: 60px;
                }

                input {
                    width: 100%;
                    height: 100%
                }

                span.checkmark {
                    top: -2px;
                    margin:0 10px;
                    display:flex;
                    justify-content:center;
                    align-items: center;
                }
            }
        }
    }
}

/*------ End Yes & No radio button Styling ------*/

/*------ radio button styling ------*/

/* The container */
label.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
label.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    min-width: 25px;
    background-color: #ccc;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container-wrapper:hover input[type=radio]:not(:checked) ~ .checkmark {
    background-color: #ccc
}

/* When the radio button is checked, add a blue background */
label.container input[type=radio]:checked ~ .checkmark {
    background-color: $colorB3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    display: none;
}

/* Show the indicator (dot/circle) when checked */
label.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
label.container .checkmark:after {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
}

/*------ End radio button styling ------*/

/*------- VehicleInfo Radio Button Styling fixes ---------*/
vehicleInfo {
    .row {
        .container-wrapper {
            .row {
                div.col-lg-6.d-flex {
                    padding: 0 !important;
                }
            }
        }

        select {
            color: $colorB3;
            font-weight: 700;
        }

        textarea {
            border-radius: 6px;
            box-shadow: 2px 2px 15px rgba(0,0,0,0.1);
            color: var(--appTextColor);
            font-weight: 500;
            border: 1px solid #ccc;
        }
    }
}
/*------- End VehicleInfo Radio Button Styling fixes ---------*/


.input-wrapper:not(mat-mdc-form-field) {
    width: 500px !important;

    button:not(.mat-icon-button), div.subCategory {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 2px 0;
        padding: 0 20px;
        background-color: white;

        svg {
            width: 25px;
            height: 50px;

            .st0 {
                stroke-width: .65px;
            }
        }

        svg.people-icon {
            width: 20px;
            height: 50px;
        }

        span {
            margin-left: 10px;
            text-align: left;
        }

        &:hover {
            background-image: linear-gradient($colorC2, $colorC3);
            color: $colorA1;
            border-color: adjust-color($colorC2, $lightness: -5%) !important;
            outline: none;

            svg {
                .st0 {
                    fill: $colorA1;
                    stroke: none;
                }
            }
        }
    }

    .subCategory-innertext-wrapper {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        padding-right: 5px;
    }

    .DTIncidentField-wrapper {
        align-items: center;

        i {
            position: relative;
            left: -25px;
        }
    }

    button.person, button.vehicle, button.subCategory, div.subCategory, button.phone {
        margin: 3px;
        padding: 10px;
        outline: none;
        border: 2px solid white;
        border-radius: 6px;
        box-shadow: 2px 2px 15px rgba(0,0,0,0.1);

        &:hover {
            @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
            color: var(--policyMemberHover);

            .policyMember {
                color: var(--policyMemberHover);
                border-color: var(--policyMemberHover);

                &:hover {
                    color: var(--policyMemberHover);
                    border-color: var(--policyMemberHover);
                }
            }
        }

        &.selected {
            @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
            outline: none;
            color: $colorA1;

            .policyMember {
                color: var(--policyMemberHover);
                border-color: var(--policyMemberHover);
            }

            &:hover {
                color: var(--policyMemberHover) !important;
            }

            svg {
                .st0 {
                    stroke: none;
                }
            }
        }

        i {
            margin-left: auto;
            color: $colorA1;
            font-size: 1.5em;
        }

        .home {
            margin-left: unset;
        }
    }

    .selected {
        background-color: $colorA1;
        color: $colorB2 !important;

        &:hover {
            i {
                color: $colorA1;
            }
        }

        i {
            color: $colorB2;
        }

        svg {
            .st0 {
                fill: #fff;
                stroke: $colorB2;
            }
        }
    }
}

.description-wrapper {
    /*padding: 0 30px !important;*/
}

#phone-icon {
  width: 100px !important;
  .st0, .st1 {
    width:auto !important;
  }
}

.emergencyResponse {
  display: flex;
  flex-wrap: wrap;
  width: 750px !important;
  justify-content: space-around;
  .icon {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-transform: uppercase;
      svg {
        width: 80px;
        height: 80px;
        .color {
          display: none;
        }
      }
    }
  }
}

.emergencyResponseReportNum-wrapper{
    width:750px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}

.newClaim-wrapper{
    max-width: 1000px !important;
    width: 100%;
}

.newClaimThirdParty-wrapper {
    max-width: 900px !important;
    width: 100%;
}

.claims-wrapper {
    max-width: 1280px !important;
    width: 100%;
}

.callout {
    color: $colorA4;
}
.checked {
    display: block !important;
}
div.icon.selectedService {
    background-color: #f1f1f1 !important;
    border: 2px solid var(--btnBorderColor) !important;
}
.button-wrapper {
    display: flex;
    justify-content: center;

    button#continueBtn, button#signInBtn {
        @include defaultBtn;
        background-color: var(--btnFillColor);
        color: var(--btnTextColor);
        border: 1px solid var(--btnBorderColor) !important;
        justify-content: center;
        border-radius: 26px;
        width: 200px;
        font-weight: bold;

        &:hover {
            @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
        }
    }

    button#backBtn {
        background-color: var(--altBtnFillColor);
        color: var(--btnBorderColor);
        border: 2px solid var(--btnBorderColor) !important;
        border-radius: 26px;
        width: 200px;
        font-weight: bold;

        &:hover {
            @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
        }
    }
}
button#continueBtn.activeBtn {
    @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
    color: #fff !important;
}

/* checkbox styling */

.card {
    width: 500px;
    margin: 50px auto;
    clear: both;
    display: block;
    padding: 0px 0px;
    background-color: #009BFF;
    border-radius: 4px;
}
.card::after {
    clear: both;
    display: block;
    content: "";
}
.card .checkbox-container {
    float: left;
    width: 50%;
    box-sizing: border-box;
    text-align:center;
    padding: 40px 0px;
}
.card .circular-container {
  background-color:#0067FF;
}

.input-title {
    clear: both;
    padding: 22px 0px 0px 0px;
    font-size: 16px;
    color: rgba(255,255,255,.6);
    font-weight: 300;
}
/* End checkbox styling */



/* Styling Checkbox Starts */
.checkbox-label {
    display: block;
    position: relative;
    margin: 10px auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 40px;
    width: 40px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width:100%;
    height:100%;
}
.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 2px solid #444B55;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: none;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color:$colorB3;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid $colorA1;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 42%;
  top: 15%;
  bottom:9%;
  width: 10px;
  height: 20px;
  border: 1px solid $colorA1;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}


/* Style for Circular Checkbox */
.checkbox-label .checkbox-custom.circular {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked ~ .checkbox-custom.circular {
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}
.checkbox-label input:checked ~ .checkbox-custom.circular::after {
    border: solid #0067FF;
    border-width: 0 2px 2px 0;
}
.checkbox-label .checkbox-custom.circular::after {
    border-radius: 50%;
}

.checkbox-label .checkbox-custom.circular::before {
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.checkbox-label input:checked ~ .checkbox-custom.circular::before {
    border-radius: 50%;
}


/* End checkbox styling */


.edit-icon{
    color:$colorB2;
    margin-left:1.25em;
    font-size:1.25em;
    &:hover{
        cursor:pointer;
        color:$colorA4;
    }
}
.contactName{
    display:flex;
    justify-content:space-between;
    margin-bottom: 0 !important;
}
.contactAddress {
    margin:0 0 1.25em !important;
    padding-bottom:5px !important;
    border-bottom:1px solid #707070;
}

.contactNameDisplay, .contactAddressDisplay{
    font-weight:600;
    font-size:1.25em;
    color:$colorB3;
}

smsConsent {
    label {
        width: 100%;
        align-self: self-end;
    }

    .phone-input-wrapper {
        display: block;
    }

    .edit-btn {
        display: block;
        text-align: end;
        margin-right: 1px;
    }

    .consent-text {
        font-size: 1em;
    }

    .invalid-text {
        display: contents;
        @extend label, .invalid;
    }
}

p.smsConsentFont {
    font-size: 1em;
}

a.normalLink{
    color:#0000ff;
}

myClaimsPic {
    .consent-text {
        font-size: 1em;
    }

    .continue-btn {
        @extend #continueBtn;
    }

    .nowrap {
        white-space: nowrap;
    }

    .invalid-text {
        display: contents;
        @extend label, .invalid;
    }
}

/* Review page Styling*/
review {
    display: flex;

    .review-container-wrapper {
        max-width: 1440px !important;
    }

    .reviewSectionHeader {
        padding: 20px;
        /*border-bottom: 1px solid $colorC5;*/
        margin-bottom: 20px;
        color: $colorC5;
    }

    header {
        display: flex;
        padding: 10px;
        color: $colorB3;
        font-weight: 500;
        border-bottom: var(--reviewCardBorder);
        align-items: center;
        text-transform: uppercase;
        justify-content: space-between;
        height: 44px;

        h5 {
            margin-bottom: 0;
        }

        .policyMember {
            font-size: 9px !important;
        }

        .ExpandAll {
            border: none;
            background-color: transparent;
            color: $colorB2;
        }

        span.iconSmall {
            width: 17px !important;
            margin: 0 8px 0 0;
            flex-shrink: 0;
        }

        button.editBtn {
            border: 1px solid $colorA1;
            border-radius: 5px;
            padding: 2px 15px;
            background-color: $colorA2;
            color: $colorA1;
            outline: none;
            font-size: 12px;

            &.selected {
                background-color: $colorA3;
            }

            &:hover {
                background-color: $colorA10;
                border: 1px solid $colorB5;
                outline: none;
            }

            &:visited {
                outline: none;
            }
        }

        .reportLabel, .year {
            font-weight: 400;
            margin-right: 5px;
        }

        .make {
            margin-right: 5px;
        }

        .media {
            display: flex;
            align-items: center;

            h5 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .policyMember {
                float: right;
            }
        }
    }

    .partiesInvolved, .incidentReport, .propDamage {
        padding: 0 25px 25px;
        background-color: $colorC4;
        border: 1px solid $colorA1;

        &:hover {
            cursor: auto;
        }
    }

    .review-input-wrapper {
        padding: 10px;
        background-color: $colorA1;
        border: 1px solid var(--reviewCardBorder);
        border-radius: 7px;
        margin: 4px 0;
        box-shadow: 2px 2px 15px rgba(0,0,0,0.1);

        header {
            span, div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .policyMember {
                background-color: transparent;
                color: var(--btnBorderColor);
                border: 1px solid var(--btnBorderColor);
                border-radius: 4px;
                font-size: 8px;
                padding: 3px 8px;
                right: 100px;
            }
        }

        &.selected {
            border: 1px solid $colorB3;
            box-shadow: 0 0 10px $colorB3;
        }
    }

    .cardFields-wrapper {
        /*border-top: 1px solid var(--reviewCardBorder);*/
        display: none !important;

        &.selected {
            display: flex !important;
            margin-top: 10px;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .labelInputWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        label, textarea, span.claimSubType-label {
            font-size: 10px;
            font-family: var(--fontFamilyHeader);
            font-weight: 300;
            color: var(--appTextColor);
            margin-bottom: 0;
        }

        input, textarea, span.claimSubType {
            position: relative;
            opacity: 1;
            border: none;
            border-radius: 0;
            font-size: 14px;
            font-weight: 600;
            color: var(--appTextColor);
            background-color: transparent;
            padding: 2px 5px;
            box-shadow: 0px 0px 0px !important;

            &:hover {
                cursor: text;
                background-color: rgba(0, 0, 0, .05);
            }
        }

        textarea {
            font-weight: 600 !important;
            font-family: var(--fontFamilyDefault);
        }
    }

    .review-gender {
        display: flex;

        .container {
            display: flex;
            align-items: center;
            padding: 0;

            input {
                display: none;
            }

            .checkmark {
                margin-right: 5px;
            }
        }
    }
}

.relDocInfo-wrapper{
    margin-top:20px;
}
.reviewPageHeading{
    width:100%;
    margin:0 auto;
}
/* End Review page Styling*/

/*Address Validation PopUp Styling*/
.addressValidationPopUp {
    padding: 30px;
    color: $colorB3;
    background-color: $colorC4;

    .addressFlex {

        div {
            text-align: center;
        }
    }

    span {
        font-size: 14px;
        font-weight: 400;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        text-transform: lowercase !important;
        text-transform: capitalize !important;
        margin-bottom: 0;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        padding: 10px;

        button {
            @include defaultBtn;
            background-color: $colorB7;
            color: $colorB3;
            font-weight: bold;
            border: 1px solid $colorA10 !important;
            padding: 10px 25px;
            border-radius: 26px;
            box-shadow: 2px 2px 5px rgba(0,0,0,.3);

            &:hover {
                @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
            }
        }

        #origBtn, #newBtn {
            @include defaultBtn;
            background-color:var(--btnFillColor);
            color:var(--btnTextColor);

        }
    }
}

/*Review Submission PopUp Styling*/
.reviewSubmissionPopUp {
    padding: 20px;
    color: $colorB3;
    background-color: $colorC4;

    .d-flex {
        div {
            text-align: center;
        }
    }

    span {
        font-size: 14px;
        font-weight: bold;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        text-transform: lowercase !important;
        text-transform: capitalize !important;
        margin-bottom: 0;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        padding: 10px;
        padding-left: 0px;

        button {
            @include defaultBtn;
            background-color: $colorB7;
            color: $colorB3;
            font-weight: bold;
            border: 1px solid $colorA10 !important;
            padding: 10px 25px;
            box-shadow: 2px 2px 5px rgba(0,0,0,.3);

            &:hover {
                @include buttonHover(var(--btnHoverHighlightColor10), var(--btnHoverHighlightColor15), var(--btnHoverBaseColor), var(--btnHoverTextColor));
            }
        }

        #origBtn, #newBtn {
            @include defaultBtn;
            background-color: var(--btnFillColor);
            color: var(--btnTextColor);
            border-radius: 26px;
        }

        #endBtn {
            @include defaultBtn;
            background-color: var(--altBtnFillColor);
            color: var(--btnBorderColor);
            border: 1px solid var(--btnBorderColor) !important;
        }
    }

    .emphasis-bold {
        color: var(--emphasisColor);
        font-weight: bold;
        font-size: 1em;
    }
}

@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.cdk-overlay-container {
    position:fixed;
}
.cdk-global-overlay-wrapper {
    justify-content: center;
    align-items: flex-start !important;

    .cdk-overlay-pane {
        top: 0 !important;
        //position: absolute !important;
        transform: translateY(-100%);
        animation: slideDown 0.5s forwards 0s ease-in;
    }
}
/*End Address Validation PopUp Styling*/
.mat-bottom-sheet-container {
    padding: 0px !important;
}

.mat-accent .mat-slider-thumb-label, .mat-slider-track-fill {
    .mat-slider-thumb-label-text {
        color: $colorB2 !important;
    }
}

confirmation {
    .textConfirmation {
        position: relative;
        top: -75px;
        padding-bottom: 0;
        margin: 25px 0 0;
        font-size: 2.3em;
    }

    div {
        position: relative;
        top: -10px;
        color: $colorB2;
        text-align: center;

        a {
            color: $colorB3;
        }
    }

    .emailConfirmationNotice {
        font-size: 25px;
        font-weight: bolder;
        padding: 15px;
        margin: 15px 0px;
        width: 100vw;
    }

    .claimNumber,
    .adjusterEmail,
    .adjusterName,
    .adjusterPhone {
        td ~ td {
            font-weight: bolder;
        }
    }
}




.review-input-wrapper.selected input:not([disabled]), .review-input-wrapper.selected textarea:not([disabled]) {
    cursor: text;
    background-color: rgba(0, 0, 0, .05);
}
.review-input-wrapper input:disabled, .review-input-wrapper textarea:disabled {
    cursor: auto !important;

    &:hover {
        background-color: transparent !important;
    }
}

additionalpartyinfo {
    .mat-slider-horizontal {
        width: 100%;
        padding: 0 15px !important;

        div {
            margin: auto !important;
        }
    }
}

:placeholder-shown {
    font-weight: 400;
}

::-webkit-input-placeholder {
    font-weight: 400;
    color: #6c757d;
}
::-moz-placeholder {
    font-weight: 400;
    color: #6c757d;
    opacity: 1;
}

:-ms-input-placeholder {
    font-weight: 400 !important;
    color: #6c757d !important;
}

.invalid::placeholder-shown {
    color:$colorC1 !important;
}
.invalid::-webkit-input-placeholder {
    color: $colorC1 !important;
}

.invalid::-moz-placeholder {
    color: $colorC1 !important;
}

.invalid:-ms-input-placeholder {
    color: $colorC1 !important;
}

.fieldLabel-wrapper{
    display:flex;
    align-items:center;
    margin-bottom:5px !important;

    &:hover{
        cursor:help;
    }
    label{
        margin:0;
    }
    i{
        color:$colorC1;
        margin:0 5px 0;
        font-size:.5em;
    }
}

.reportNumber-wrapper {
    width: 220px;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    label{
        font-size:.75em;
    }
}
select, mat-select {
    font-weight: 700;
}
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option{
    height:1.5em;
}
.mat-select-panel-wrap {
    width: 100% !important;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
    border: 1px solid #a6a6a6 !important;
    position: relative !important;
    right: -30px !important;
    top: 44px !important;
    box-shadow: none !important;
    min-width: 105% !important;
    color:#3b4967 !important;
    border-radius:0 !important;
    &:hover{
        color:#fff !important;
    }
}
.mat-select-value {
    padding: 0 5px;
    color: var(--appTextColor) !important;
}
.mat-option-text, .mat-option, .mat-selected {
    color: #3b4967 !important;
    &:hover{
        color:#fff !important;
    }
}

.mat-select-panel .mat-option{
    height:1.5em !important;
    padding:0 15px !important;
    font-weight:400;
    &:hover * {
        color:#fff !important;
    }
}

.mat-option:focus:not(.mat-option-disabled), .mat-option:hover:not(.mat-option-disabled) {
    &:hover {
        background-color: #007bff;
        color: #fff !important;
    }
}

    .mat-select-arrow {
        transform: scaleX(.55);
    }

.mat-option, .mat-option.mat-active, .mat-selected{
    padding:0;
    border:none;
    background-color: transparent;
    color: #fff !important;
    &:hover {
        color: #fff !important;
    }
}
.mat-option-ripple.mat-ripple{
    &:hover{
        color:#fff;
    }
}
.mat-pseudo-checkbox{
    border:2px solid !important;
}
.mat-pseudo-checkbox-checked{
    border:2px solid transparent !important;

}

/* Media Queries */

/* Super small devices (Galaxy Fold) */
@media only screen and (max-width: 300px){
    div.claims-innertext {
        font-size: 5vw;

        p {
            font-size: 4vw !important;
        }
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    breadcrumb {

        .mobile {
            display: flex;
        }

        .desktop {
            display: none;
        }
    }

    claimType {
        .input-wrapper button.subCategory, div.subCategory {
            min-height: 56px;
            height: unset;
        }
    }

    header nav {
        padding-left: 10px;
    }

    .main-inner-wrapper form {
        width: unset;
    }

    main#main {
        width: 100%;
        margin-top: 10px;
    }

    main p {
        padding-bottom: unset;
    }

    .main-inner-wrapper {
        width: 100% !important;
        padding: 5px 30px 30px 30px;
    }

    .button-wrapper, button#continueBtn, button#backBtn {
        width: 80% !important;
        align-self: center !important;
    }

    .input-wrapper {
        width: 100% !important;
    }

    .emergencyResponse {
        width: 350px !important;
        .icon {
            margin: 10px 0 !important;
        }
    }

    .emergencyResponseReportNum-wrapper{
        width: 350px !important;    
    }

    newClaim {
        .emergencyHeader {
            width: 250px !important;
            .h4 { font-size: 1.25rem; }
            .h5 { font-size: 1rem; }
            flex-direction: column;
        }

        .content-outer-wrapper {
            padding: 20px;
            flex-direction: column;

            .content-inner-wrapper {
                p {
                    font-size: 7vw;
                }

                &:first-child {
                    border-right: none;
                    border-bottom: 1px solid $colorB5;
                }
            }
        }
    }

    vehicleInfo, vehicledetail {
        .content-wrapper {
            width: unset;
            margin: 5px;

            .row {
                max-width: 375px !important;
            }

            .button-wrapper{
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    lossdescription {
        .main-inner-wrapper {
            & > div{
                width: unset;
            }
        }
    }

    .container-wrapper {
        .container {
            input {
                max-width: 150px !important;
            }
        }
    }

    confirmation {
        .emailConfirmationNotice {
            font-size: 20px;
        }
    }

    .reportNumber-wrapper {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            font-size: .75em;
        }

        input {
            width: 50%;
        }
    }

    button.person, button.vehicle, button.subCategory, button.phone {
        width: 250px;
    }

    div.subCategory {
        width: 250px !important;
    }

    .car-detail-wrapper {
        transform: scale(0.85);
    }

    smsConsent {
        .margin-top {
            margin-top: 14px;
        }
    }

    myClaimsPic {
        .margin-top {
            margin-top: 14px;
        }
    }


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    breadcrumb {
        .mobile {
            display: flex !important;
        }

        .desktop {
            display: none;
        }
    }

    .main-inner-wrapper {
        p {
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 20px;
        }

        .header {
            font-size: 1.5em;
        }

        .h5 {
            font-size: 1rem;
        }

        .emailConfirmationNotice {
            margin-top: 15px;
        }
    }

    newClaim {
        .emergencyHeader {
            width: 625px !important;
            flex-direction: column;
        }
    }

    div.claims-innertext {
        p {
            padding: 0;
        }
    }

    .textConfirmation {
        padding-left: 15px;
        padding-right: 15px;
    }

    vehicleInfo, vehicledetail {
        .content-wrapper {
            width: unset;
        }
    }

    lossdescription {
        .main-inner-wrapper {
            & > div {
                width: 85%;
            }
        }
    }

    .emergencyResponse {
        width: unset !important;

        .icon {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .reportNumber-wrapper {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            font-size: .75em;
        }

        input {
            width: 50%;
        }
    }

    button.person, button.vehicle, button.subCategory, div.subCategory, button.phone {
        width: 250px !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {

    breadcrumb {

        .mobile {
            display: flex !important;
        }

        .desktop {
            display: none;
        }
    }

    // media max width 768px
    newClaim {
        .emergencyHeader {
            width: 700px !important;
            flex-direction: column;
        }

        .content-outer-wrapper {
            padding: 20px;
            flex-direction: column;

            .content-inner-wrapper {
                &:first-child {
                    border-right: none;
                    border-bottom: 1px solid $colorB5;
                }
            }
        }
    }

    .userName {
        display: none;
    }

    main {
        margin-top: 0;

        p {
            font-size: 2em;
            width: 100%;
        }
    }

    vehicleinfo, vehicledetail {
        & > p {
            font-weight: 400;
        }

        p {
            padding: 0 20px;
        }

        .carInfo {
            padding: 0 20px;
            font-size: .75em;
        }

        .content-wrapper {
            width: 100% !important;
            margin: 0 !important;
        }

        button#continueBtn {
            margin: 0 40px;
        }
    }

    .emailConfirmationNotice {
        margin-top: 30px !important;
    }

    button.person, button.vehicle, button.subCategory, div.subCategory, button.phone {
        width: 400px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    breadcrumb {

        .mobile {
            display: none;
        }

        .desktop {

            .outer-wrapper {
                .inner-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .line {
                        width: 5.3em !important;
                    }
                }

                p {
                    display: none;
                }

                &:last-child {
                    width: 50px;

                    .inner-wrapper {
                        display: none;
                    }
                }
            }
        }
    }

    .daterangepicker {
        width: 261px !important;
        padding: 0 !important;
        margin: 0 !important;
        text-align: center;

        .calendar {
            width: 100% !important;
            margin: 0 !important;
            padding: 10px !important;

            .calendar-table {
                .month, th {
                    color: var(--appTextColor);
                }

                td {
                    color: var(--appTextColor);

                    &.active {
                        color: $colorA1;
                    }
                }
            }
        }

        .calendar-time {
            padding-left: 0 !important;

            .hourselect, .minuteselect, .ampmselect {
                border: 1px solid rgba(0,0,0, .15);
                color: var(--appTextColor);
            }

            i {
                display: none;
            }
        }

        .daterangepicker_input {
            padding-right: 0 !important;
        }
    }

    .ranges {
        width: 100% !important;
        border-top: 1px solid rgba(0,0,0, .15);
        margin: 10px 0 0;
        padding: 10px !important;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        margin: 0 !important;

        button {
            padding: 5px 25px !important;

            &.cancelBtn {
                background-color: rgba(0,0,0, .05);
            }
        }
    }

    .partiesInvolved, .incidentReport, .propDamage {
        header.reviewSectionHeader {
            padding: 20px 0 !important;
        }
    }

    lossdescription {
        .main-inner-wrapper {
            & > div {
                width: 65% !important;
            }
        }
    }

    vehicleinfo, vehicledetail {
        & > p {
            font-weight: 400;
        }

        p {
            padding: 0 20px;
        }

        .carInfo {
            padding: 0 20px;
            font-size: .75em;
        }

        .content-wrapper {
            width: 100% !important;
            margin: 0 !important;
        }

        button#continueBtn {
            margin: 0 40px;
        }
    }

    button.person, button.vehicle, button.subCategory, div.subCategory, button.phone {
        width: 400px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    button.person, button.vehicle, button.subCategory, div.subCategory, button.phone {
        width: 400px !important;
    }
}
/* End Media Queries */

select:disabled, input:disabled {
    background-color: rgb(235, 235, 228);
}
/* New Mobile Redesign */
@import './styles/mobile.scss';
@import './styles/claimType.scss';
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
