//Light Colors
$colorA1: #fff; // White
$colorA2: #ccc; // Charcoal
$colorA3: rgb(201, 201, 201); // Medium Grey
$colorA4: #f28f2d; // Orange
$colorA5: #e1e1e1; // Grey
$colorA6: #e19a9c; // Under Saturated Red
$colorA7: #EACCCC; // Pink
$colorA8: #f1f1f1; // Light Grey
$colorA9: #1ac318; // Green
$colorA10: #9f9f9f; // Input, Select border color (light grey)
$colorA11: #6b6b6b; // Medium Grey
$colorA12: #005fae; // NGIC Blue

//Dark Colors
$colorB1: #000; // Black
$colorB2: #707070; // Dark Grey
$colorB3: #3B4967; // Dark Blue
$colorB4: #C1272D; // Red
$colorB5: #808285; // NGIC Dark Gray
$colorB7: #fec665; // NGIC Light orange
$colorB8: #d1d3d4; // NGIC Light Gray

$colorC1: #c1272d; // Warning Red
$colorC2: #64C63F; // Light Green
$colorC3: #39B54A; // Medium Green
$colorC4: #f1f1f1; // Light Gray
$colorC5: #575757; // Dark Gray
$colorC6: #FAFAFA; // Background

:root {
    --fontFamilyHeader: 'Lato';
    --fontFamilyBody: 'Lato';
    --fontFamilyDefault: 'Lato';
    --logo: url(/content/brand/ngic/ngic-logo.svg);
    --pageTitle: "Page Title default value";
    --footer: "Footer default value"; 
    --background-img:url(/content/images/bkgArrows.png);
    // Navbar
    --navBarBottomColor: #{$colorA11};
    --navBarBackgroundColor: #{$colorA1};
    //App Element Colors
    --appTextColor: #{$colorC5};
    --borderColor: #{$colorA5};
    --inputBorderColor: #{$colorA10};
    --iconBorderColor: #{$colorB8};
    --reviewCardBorder: #{$colorB8};
    --labelFontColor: #{$colorC5};
    --footerColor: #{$colorA11}; 
    --emphasisColor: #{$colorA11};
    --progressColor: #{$colorC3};
    --progressColorDefault: #{$colorB8};
    // Button Defaults
    --btnFillColor: #{$colorA11};
    --btnTextColor: #{$colorA1};
    --btnBorderColor: #{$colorB8};
    // Button Hover Effect
    --btnHoverHighlightColor10: #{lighten($colorC2, 10%)};
    --btnHoverHighlightColor15: #{lighten($colorC2, 15%)};
    --btnHoverBaseColor: #{$colorC3};
    --btnHoverBorderColor: #{$colorC2};
    --btnHoverShadowColor: #{$colorC3};
    --btnHoverTextColor: #{$colorA1};
    --policyMember: #{$colorA11};
    --policyMemberHover: #{$colorA1};
    // Secondary Button Colors
    --altBtnFillColor: #{$colorC6};
    --altBtnTextColor: #{$colorA12};
    --altBtnBorderColor: #{$colorA12};
}