@mixin buttonHover($btnHoverHighlightColor10, $btnHoverHighlightColor15, $btnHoverBaseColor, $btnHoverTextColor) {
    background-image: linear-gradient($btnHoverHighlightColor10, $btnHoverBaseColor);
    border: 1px solid $btnHoverHighlightColor15 !important;
    //box-shadow: 0 0 10px $btnHoverBaseColor;
    color: $btnHoverTextColor !important;
}

@mixin defaultBtn {
    width: 150px;
    padding: 10px;
    margin: 20px 2px;
    text-align: center;
}